.profile-bg {
  /* background-image: url('../assets/images/formBg.png'); */
  background-size: 100% auto;
  background-position: top center;
  background-repeat: no-repeat;
}

.profile-add-wallet {
}

.profile-add-wallet:hover {
  transform: translateY(2px);
}

/* .profile-card {
  transition: 0.2s;
  cursor: pointer;
}

.profile-card:hover {
  transform: scale(1.04);
} */

/* body.active button.profile-add-wallet{
    background-image: url("../assets/images/heroButtonBgLight.png") !important;
}

body.active button.profile-add-wallet:hover{
    background-image: url("../assets/images/profileAddWalletLightHover.png") !important;
}
body.active button.profile-add-wallet:hover{
    color: #fff !important;
} */

.profile-add-wallet:hover {
  transform: translateY(-4px);
}

body.active button.profile-add-wallet {
  background-image: url("../assets/images/profileAddWalletLightHover.png") !important;
  color: #fff !important;
}

body.active .profile-card {
  background-image: url("../assets/images/profileCardBgLight.png") !important;
}

body.active .profile-bg {
  background-image: url("../assets/images/bgBottomLight.png");
  background-position: bottom center;
}

@media only screen and (max-width: 1300px) {
  .profile-card-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 1100px) {
  .profile-card-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 800px) {
  .profile-card-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
