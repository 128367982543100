body.active .carouselBorders {
  background-image: url("../../assets/images/heroCarouselBorderLight.png") !important;
}

.hero-carousel-item {
  height: 65vh;
}

.react-multi-carousel-dot button {
  background-color: white;
  border: none;
  border-radius: 999px;
  transition: width 0.3s ease;
}
.react-multi-carousel-dot--active button {
  width: 30px;
}

@media only screen and (max-width: 800px) {
  .hero-carousel-item {
    height: 40vh;
  }
}

@media only screen and (max-width: 600px) {
  .hero-carousel-item {
    height: 25vh;
  }
}
