body.active .selectNetworkItems {
  background-image: url("../../assets/default/InnerFrame_1Light.png") !important;
}
body.active .selectNetworkItems:hover{
  background-image: url("../../assets/default/InnerFrame_1LightHover.png") !important;
}

.networks {
  width: 500px;
}

.network > div {
  height: 60px;

}

@media (max-width: 720px) {
  .network {
  }

  .network > div {
    height: 60px;
  }

  .networks {
    width: auto;
  }
}