.flex-w-half {
  width: 45%;
}

.claims-bg {
  background-size: 100% auto;
  background-position: top center;
  background-repeat: no-repeat;
}

body.active .claims-bg {
  background-image: url("../../assets/images/bgBottomLight.png");
  background-position: bottom center;
}

body.active .light-border {
  border-color: rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 1200px) {
  .claims-top-container {
    flex-direction: column;
    align-items: center;
  }

  .flex-w-half:first-child {
    margin-bottom: 30px;
  }
  .flex-w-half {
    width: 80%;
  }
}

@media screen and (max-width: 600px) {
  .flex-w-half {
    width: 95%;
  }
}
