@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap");

@font-face {
  font-family: "Scotia";
  src: local("Scotia"),
    url("./assets/fonts//casanova_scotia/Casanova\ Scotia.otf")
      format("truetype");
}
@font-face {
  font-family: "Orionis";
  src: local("Orionis"),
    url("./assets/fonts//gamma_orionis/Gamma\ Orionis.otf") format("truetype");
}
@font-face {
  font-family: "Quest";
  src: local("Quest"),
    url("./assets/fonts//space_quest/Space\ Quest.ttf") format("truetype");
}

body {
  font-family: Scotia;
}

.bg-main {
  background-image: url("./assets/images/formBg.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% auto;
}
body.active .bg-main {
  background-image: none;
}

.orionis {
  font-family: Orionis !important;
}

.quest {
  font-family: Quest !important;
}

.scotia {
  font-family: Scotia !important;
}

.roboto {
  font-family: "Roboto", sans-serif !important;
}
