body.active .pool-expand-btn {
  background-image: url("../../assets/images/frameheadingLight.png") !important;
}

.custom-btn {
  transition: 0.2s;
}

.custom-btn:hover {
  margin-left: 20px;
  /* margin-right: 20px; */
  transform: translateX(2px);
}
