@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --text-color: #fff;
  --text-color2: gold;
  --main-color: #e02f6b;
  --blue: #0000ff;
  --blue-dark: #18293c;
  --orange: #ffa500;
  --green-yellow: #cddc39;
  --pink-light: #efa2b4;
  --cyan-light: #aef1ee;
  --white: #fff;
  --d-text: rgb(23, 0, 47);
  --bg-color: rgb(23, 0, 47);
  --bg-light-color: linear-gradient(
    52deg,
    rgba(23, 0, 47, 1) 44%,
    rgba(33, 0, 68, 1) 52%,
    rgba(23, 0, 47, 1) 60%
  );
  --bg-light-color2: url("./assets/svgs/Rectangle.svg");
  /* --bg-light-color2: radial-gradient(
    ellipse at top,
    rgba(228, 235, 56, 0.8578781854538691),
    transparent 30%
  ), 
  radial-gradient(ellipse at bottom, rgba(23, 0, 47, 1) 100%, transparent); */
}

body {
  background-image: url("./assets/images/bg.png");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-color: #140e1b;
}
body.active {
  --text-color: #0a1848;
  --text-color2: #ff6468;
  --main-color: #5e0087;
  --blue: #0000ff;
  --blue-dark: #18293c;
  --orange: #ffa500;
  --green-yellow: #cddc39;
  --pink-light: #380036;
  --cyan-light: #191970;
  --bg-color: #fff;
  --bg-light-color: #fff;
  --bg-light-color2: #fff;
  --d-text: #0a1848;
}
body.active {
  background-color: rgba(223, 232, 237, 1);
  background-image: url("./assets/images/bgTopLight.png") !important;
  background-size: 100% auto;
  background-position: top center;
  background-attachment: initial;
  color: rgb(4, 3, 58);
}
p {
  font-size: 0.7rem;
}
/* @media screen and (max-width: 900px){
    p{
        font-size: 0.5rem;
    }
} */

/* 
    General CSS
*/
.xlHeadingText {
  @apply text-5xl uppercase text-white;
}
.mdHeadingText {
  @apply text-2xl uppercase text-white;
}
.smHeadingText {
  @apply text-xl uppercase text-white;
}
.subtitle {
  @apply mb-1 text-white text-xs;
}

.ReactModal__Overlay--after-open {
  /* background-color: rgba(23, 0, 47, 0.85) !important; */
  background-color: rgba(0, 0, 0, 0.85) !important;
}

.ReactModal__Content--after-open {
  border: 1px solid rgba(0, 0, 0, 0.8) !important;
  background-color: rgba(23, 0, 47, 1) !important;
  /* width: 60%; */
  overflow-y: scroll !important;
  max-height: 90%;
}

body.active .ReactModal__Overlay--after-open {
  /* background-color: rgba(23, 0, 47, 0.85) !important; */
  background-color: rgba(255, 255, 255, 0.85) !important;
}

body.active .ReactModal__Content--after-open {
  border: none !important;
  background-color: #e6eff5 !important;
  /* width: 60%; */
  overflow-y: scroll !important;
  max-height: 90%;
}

.ReactModal__Content--after-open::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.ReactModal__Content--after-open {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

button:disabled {
  cursor: no-drop;
}
