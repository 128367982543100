header {
  width: 100%;
  padding: auto;
  /* text-align: center; */
}


@media only screen and (max-width:1200px){
  .sm-flex-w-full{
    width: 100% !important;
  }
}