.flex-w-half {
  width: 45%;
}

.inline-gap:not(:last-child) {
  margin-right: 10px;
}
.inline-gap {
  margin-bottom: 10px;
}

.stake-bg {
  background-size: 100% auto;
  background-position: top center;
  background-repeat: no-repeat;
}

.stake-btn-type2 {
  background-color: rgba(0, 0, 0, 0.2);
}
.stake-btn-type2:hover {
  background-color: #68483c;
}

.stake-btn-active {
  background-color: #68483c;
}

.unselected-stake-category {
  background-image: url("../../assets/images/detailsCardButton.png");
}

.selected-stake-category {
  background-image: url("../../assets/default/Frame-4.png");
}

body.active .stake-left-card {
  background-image: url("../../assets/images/detailsTopLeftCardLight.png") !important;
}

body.active .stake-btn-type1 {
  background-image: url("../../assets/images/detailsCardButtonLight.png") !important;
}

body.active .stake-bg {
  /* background-image: url("../../assets/images/bgBottomLight.png"); */
  background-position: bottom;
  margin-bottom: 28px;
}

body.active .light-border {
  border-color: rgba(0, 0, 0, 0.1);
}

body.active .stake-btn-type2 {
  border-color: rgba(0, 0, 0, 0.1);
  background-color: #eceff6;
}
body.active .stake-btn-type2:hover {
  background-color: #13204c;
  color: #fff !important;
}

body.active .selected-stake-category {
  background-image: url("../../assets/hover/stake-light-btn.png") !important;
}

.selected-duration {
  background-color: #68483c;
}

body.active .selected-duration {
  color: white !important;
  background-color: #13204c !important;
}

.stake-btn-type3 {
  transition: 0.3s;
}

.stake-btn-type3:hover {
  transform: translateY(-4px);
}

body.active .stake-btn-type3 {
  background-image: url("../../assets/images/stackingLeftCardButtonLight.png") !important;
}

@media only screen and (max-width: 991px) {
  .stake-container {
    flex-direction: column;
    align-items: center;
  }

  .flex-w-half:first-child {
    margin-bottom: 30px;
  }
  .flex-w-half {
    width: 80%;
  }
}

@media screen and (max-width: 600px) {
  .flex-w-half {
    width: 95%;
  }
}
