.loader {
  background-color: rgba(0, 0, 0, 0.95);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;

  z-index: 10000000;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.loader img {
  animation: rotation 2s infinite linear;
  width: 80px;
  margin: 20px;
}

.loader p {
  color: white !important;
  font-size: 18px;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
