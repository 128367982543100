body.active .tcard {
  background-image: url("../../assets/images/heroCardBGLight.png") !important;
  box-shadow: none;
}

body.active .t-card-button {
  background-image: url("../../assets/images/tCardButtonLight.png") !important;
}

.tcard {
  transition: 0.3s;
  cursor: pointer;
}

.tcard:hover {
  transform: scale(1.05);
}

@media screen and (max-width: 1200px) {
  .tcardContainer {
    grid-template-columns: repeat(2, 1fr);
  }
  .tcardContainer .tcard:last-child {
    grid-column: 1 / span 2;
  }
}

@media screen and (max-width: 800px) {
  .tcardContainer {
    grid-template-columns: repeat(1, 1fr);
  }
  .tcardContainer .tcard:last-child {
    grid-column: 1 / span 1;
  }
}

@media screen and (max-width: 376px) {
  .tcard p {
    font-size: 0.4rem;
  }

  .tcard button {
    bottom: 1rem;
  }
}
