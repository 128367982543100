ul {
  list-style: none;
}
h2,
h3,
p,
li {
  color: var(--text-color);
}
span {
  color: var(--text-color2);
}
.cryptio__footer-main {
  width: 45%;
  display: flex;
  justify-content: space-between;
}
.cryptio__footer-main a {
  font-size: 0.9rem;
}
.cryptio__footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 50%;
  padding: 3rem;
  color: rgb(177, 177, 177);
  /* background: rgb(23, 0, 47);
  background: linear-gradient(
    52deg,
    rgba(23, 0, 47, 1) 44%,
    rgba(33, 0, 68, 1) 52%,
    rgba(23, 0, 47, 1) 60%
  ); */
}
body.active .cryptio__footer {
  padding-top: 0 !important;
}
.cryptio__footer-head {
  width: 100%;
  display: flex;
  min-height: 40vh;
  justify-content: space-between;
  border-bottom: 1px solid rgb(41, 41, 41);
  margin-bottom: 2rem;
}
body.active .cryptio__footer-head {
  border-bottom: 1px solid rgb(41, 41, 41, 0.2);
}
.cryptio__footer-1 {
  width: 20%;
}
.cryptio__footer-1 h2 {
  font-weight: 800;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}
.cryptio__footer-2 h2 {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}
.cryptio__footer-3 h2 {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}
.cryptio__footer-4 h2 {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}
.cryptio__footer-5 {
  display: flex;
  flex-direction: column;
  width: 20%;
}
.cryptio__footer-5 h2 {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}
.cryptio__footer-5 ul {
  display: flex;
  justify-content: space-between;
  font-size: 2rem;
  color: var(--text-color);
}
.cryptio__footer-copy {
  text-align: center;
}
.cryptio__footer-2 ul li,
.cryptio__footer-3 ul li,
.cryptio__footer-4 ul li,
.cryptio__footer-5 ul li {
  cursor: pointer;
}

.cryptio__footer-2 ul li:hover,
.cryptio__footer-3 ul li:hover,
.cryptio__footer-4 ul li:hover,
.cryptio__footer-5 ul li:hover {
  color: rgb(153, 153, 153);
}
body.active .cryptio__footer {
  background: url("../../assets//lightSvg/Vector.svg") !important;
}
@media screen and (max-width: 991px) {
  .cryptio__footer {
    /* background:  var(--bg-color) !important; */
    /* background: var(--bg-light-color); */
    padding: 2rem;
  }
  .cryptio__footer-1 {
    width: 100%;
  }
  .cryptio__footer-2 {
    width: 100%;
  }
  .cryptio__footer-3 {
    width: 100%;
  }
  .cryptio__footer-head {
    flex-direction: column;
    padding-bottom: 1rem;
  }
  .cryptio__footer-main {
    width: 100%;
    justify-content: space-between;
    margin: 1rem 0;
  }
  .cryptio__footer-2 ul li,
  .cryptio__footer-3 ul li,
  .cryptio__footer-4 ul li {
    font-size: 0.6rem;
  }
  .cryptio__footer-5 {
    width: 70%;
  }
  .cryptio__footer-copy {
    font-size: 0.5rem;
  }
}
