.flex-w-half {
  width: 45%;
}

.details-bg {
  background-size: 100% auto;
  background-position: top center;
  background-repeat: no-repeat;
}

.details-about-heading {
  grid-template-columns: auto auto;
}

.details-btn-type1 {
  background-image: url("../assets/images/detailsCardButton.png");
}

.yellow-selected-btn {
  background-image: url("../assets/default/Frame-4.png") !important;
}

body.active .details-left-card {
  background-image: url("../assets/images/detailsTopLeftCardLight.png") !important;
}

body.active .details-info-card {
  background-image: url("../assets/images/detailsInfoCardBGLight.png") !important;
}

body.active .details-btn-type1 {
  background-image: url("../assets/images/detailsCardButtonLight.png") !important;
}

body.active .yellow-selected-btn {
  background-image: url("../assets/default/Frame-4.png") !important;
  color: white !important;
}
body.active .details-bg {
  background-image: url("../assets/images/bgBottomLight.png");
  background-position: bottom center;
}

.selected-pool-phase {
  background-image: url("../assets/default/Frame-4.png") !important;
}

body.active .selected-pool-phase {
  background-image: url("../assets/hover/stake-light-btn.png") !important;
}

body.active .rate-card {
  background-image: url("../assets/images/Path1.png") !important;
}

body.active .detail-info-card-button {
  background-image: url("../assets/images/detailInfoCardButtonLight.png") !important;
}

body.active .light-border {
  border-color: rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 1200px) {
  .details-top-container {
    flex-direction: column;
    align-items: center;
  }

  .grid-details-about {
    grid-template-columns: 1fr;
  }

  .flex-w-half:first-child {
    margin-bottom: 30px;
  }
  .flex-w-half {
    width: 80%;
  }
}

@media screen and (max-width: 600px) {
  .flex-w-half {
    width: 95%;
  }
}
