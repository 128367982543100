.cardfeature {
  display: flex;
  flex-direction: column;
  height: 25rem;
  width: 39rem;
  margin: 1rem;
}

.firstcard {
  display: flex;
  flex-direction: row;
  /* position: relative; */
  height: 50%;
  width: 98%;
  margin: 0.3rem;
}
.secondcard2 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45%;
  width: 98%;
  margin: 0.3rem;
  border: 2px solid rgb(255, 255, 255, 0.2);
  border-radius: 10px;
  background-image: var(--bg-light-color2);
  background-position: center center;
  background-size: cover;
  padding: 1rem;
}

body.active .firstcard,
body.active .secondcard2 {
  border-color: rgb(0, 0, 0, 0.1);
}

.cardtext {
  height: 100%;
  width: 80%;
  background: var(--bg-color);
  background: var(--bg-light-color);
  padding-left: 0.5rem;
}

.cardimage {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 20%;
  background: var(--bg-color);
  background: var(--bg-light-color);
}

body.active .card-image-phase {
  background-image: url("../../assets/images/group3Light.png") !important;
}
body.active .card-image-phase p {
  color: white !important;
}

.upper {
  height: 40%;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.lower {
  height: 60%;
  width: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.cyptia__ucard-body-details,
.cyptia__ucard-body-progress {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cyptia__ucard-body-details {
  width: 100%;
  justify-content: space-between;
}
.cyptia__ucard-body-progress {
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  font-size: 0.5rem;
  color: #fff;
  margin-top: 0.5rem;
}
.cyptia__ucard-body-details-total {
  font-weight: 800;
}

.cyptia__ucard-body-progress-text1 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.cyptia__ucard-body-details-max {
  width: 30%;
}
.cyptia__ucard-body-details-max h1,
.cyptia__ucard-body-details-access h1 {
  color: var(--text-color2);
  font-weight: 500;
}

.featureCard {
  display: grid;
  /* flex-direction: row; */
  /* justify-content: center; */
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

@media screen and (max-width: 991px) {
  .featureCard {
    /* flex-direction: column;
      overflow: hidden;
      align-items: center;
      flex-wrap: nowrap; */
    grid-template-columns: 1fr;
  }
  .cardFeatures {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 2rem;
  }
  .cardfeature {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 !important;
    margin-top: 1rem;
    height: 60vh;
  }
  .lower p {
    overflow: auto;
  }
}
@media screen and (max-width: 600px) {
  /* .cyptia__ucard-body-details-total {
      font-size: 0.5rem;
    }
    .cyptia__ucard-body-details-total h1{
      font-size: 0.5rem;
    }
    .cyptia__ucard-body-details-max{
      font-size: 0.5rem;
    }
    .cyptia__ucard-body-details-max h1{
      font-size: 0.5rem;
    }
    .cyptia__ucard-body-details-access{
      font-size: 0.5rem;
    }
    .cyptia__ucard-body-details-access h1{
      font-size: 0.5rem;
    } */
  .upper div {
    /* height: 3vh !important; */
    width: 50% !important;
  }
  /* .upper div p{
      font-size: 0.4rem !important;
      }
    .lower p{
      font-size: 0.4rem !important;
    } */
  .cardtext {
    padding: 0.4rem;
  }
  /* .cardimage div p{
      font-size: 0.2rem !important;
    } */
  .cardfeature {
    /* height: 50vh; */
  }
}

@media screen and (max-width: 500px) {
  .cardfeature {
    /* height: auto; */
    /* width: 80%; */
  }
}
