.navbar {
  font-size: 0.8rem;
}
.navbar .nav-left img {
  margin-right: 50px;
}
.navbar a {
  color: var(--text-color);
}
.nav-titles a:hover {
  cursor: pointer;
  border-bottom: 2px dashed gold;
}
body.active .nav-titles a:hover {
  color: red;
  border-color: #444;
}

.nav-right {
  background-size: "100%, 100%";
}

.nav-right button {
  background-size: 100% 100%;
}

body.active .nav-right button {
  background-image: url("../../assets/images/heroButtonBgLight.png") !important;
}

body.active .user-frame {
  background-image: url("../../assets/images/userFrameLight.png") !important;
}

.nav-user-details .users {
  outline: none;
  border: none;
  background-color: transparent;
}

.nav-user-details h2 {
  color: var(--text-color);
}
input:focus {
  outline: none;
  border: none;
}
.toggleBars {
  display: none;
}

.connect-wallet-btn {
  background-size: 100% 100% !important;
  background-image: url("../../assets/default/Frame-1.png");
  background-position: center;
  transition: 0.1s;
}

.connect-wallet-btn:active {
  transform: translateY(3px);
}

@media screen and (max-width: 991px) {
  .toggleBars {
    display: block;
    font-size: 2rem;
    color: var(--text-color);
  }
  .navbar {
    margin: 0 auto !important;
  }
  .nav-right button {
    font-size: 0.5rem;
  }
  .nav-right .nav-user {
    display: none;
  }
  .navbar .nav-titles {
    width: auto;
  }
  .navbar .nav-titles a {
    display: none;
  }
  .nav {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: var(--bg-color);
    z-index: 10;
  }
  .nav .nav-toggler {
    position: absolute;
    top: 5%;
    right: 5%;
    font-size: 2rem;
    color: var(--text-color);
  }
  .nav .nav-toggler span {
    background-color: transparent;
  }
  .nav .nav-toggler span::before {
    transform: rotate(45deg);
  }
  .nav .nav-toggler span::after {
    transform: rotate(-45deg);
  }
  .navbar .nav .nav-toggler:hover span::before,
  .navbar .nav .nav-toggler:hover span::after {
    width: 100%;
  }
  .nav-inner {
    min-height: calc(100vh - 70px);
    max-width: 1200px;
    margin: auto;
    padding: 50px 0;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .nav-inner ul li {
    text-align: center;
  }
  .nav-inner ul li a {
    font-size: 40px;
    text-transform: capitalize;
    color: var(--text-color);
    display: block;
    font-weight: 500;
    padding: 8px 15px;
    transition: color 0.3s ease;
    position: relative;
  }
  .nav-inner ul li a::before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    height: 50%;
    width: 0%;
    z-index: -1;
    transition: width 0.5s ease;
  }
  .nav-inner ul li a:hover::before {
    width: 100%;
  }
  .nav-inner ul li a:hover {
    color: var(--main-color);
  }
  .navbar {
    margin-top: 0;
  }
}
