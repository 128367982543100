.cardCenterItems {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

h2.smHeadingText {
  width: 400px;
}

.networkName > img {
  margin-right: 0.8rem;
}

.subtitle {
  /* color: #b2aeb6; */
  opacity: 0.7;
}

body.active .avatar {
  border-color: black;
}

.claimCard {
  background-size: 100% 100%;
  background-image: url("../../assets/default/Path.png");
  padding: 16px 10px 25px;
  margin-bottom: 20px;
}

body.active .claimCard {
  padding: 16px 10px 25px;
  margin-bottom: 20px;
  background-image: url("../../assets/default/PathLight.png");
  /* background-color: white; */
}

@media (max-width: 1280px) {
  .cardRight {
    display: block;
    margin-right: 2rem;
  }
  .cardCenterItems.flex {
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 0.7rem;
  }

  h2.smHeadingText {
    width: 100%;
    font-size: 1.1em;
  }
  span {
    font-size: 0.9em;
  }
  .subtitle {
    font-size: 0.8em;
  }
}

@media (max-width: 980px) {
  .rightSide {
    flex-wrap: wrap;
  }
  .cardRight {
    margin: 0;
  }
  h2.smHeadingText {
    font-size: 0.9em;
  }
  .cardCenterItems span {
    font-size: 0.7em;
  }
  .subtitle {
    font-size: 0.6em;
  }

  .claimCard {
    /* padding-bottom: 2rem; */
  }

  .cardCenterItems.flex {
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  .cardCenterItemItem:first-child {
    border-left: 0;
    padding-left: 0;
  }
  .cardContent {
    padding: 1rem 0.5rem;
  }

  .cardBtn {
    /* margin-top: 2rem; */
    /* margin-top: 1rem; */
    margin-top: 1rem;
    margin-bottom: 1rem;
    /* padding: .3rem; */
  }
  .networkName > img {
    height: 1.299rem;
    align-items: center;
  }
}

@media (max-width: 550px) {
  .cardCenterItems {
    flex-direction: column;
    gap: 0.5rem;
  }

  .cardCenterItemItem {
    border-left: 0;
    border-top: 2px;
    padding-left: 0px;
  }

  .claimCard {
    /* padding-bottom: 3rem; */
  }
  .claimCard {
    /* padding: 16px 10px 25px; */
    margin-bottom: 20px;
    padding: 0;
    background-color: #1a072b;
    background-image: none !important; /* background-color: white; */
  }

  body.active .claimCard {
    padding: 0;

    /* padding: 16px 10px 25px; */
    background-color: white !important;
  }
}
