body.active .carouselContainerCards1 {
  background-image: none !important;
}

body.active .ContainerCards {
  background-image: url("../assets/images/heroButtonBgLight.png") !important;
}

body.active .body-bg-bottom {
  background-image: url("../assets/images/bgBottomLight.png");
  background-size: 100% auto;
  background-position: bottom center;
  background-repeat: no-repeat;
}

body.active .white-pb-38 {
  padding-bottom: 9.5rem;
}
.ContainerCards {
  transition: 0.3s;
}

.ContainerCards:hover {
  background: url("../assets/images/profileAddWalletLightHover.png") no-repeat !important;
}

@media screen and (max-width: 991px) {
  .imageCard {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .carouselContainerCards1,
  body.active .carouselContainerCards1 {
    margin-top: 0;
    padding-top: 0;
  }

  .carouselContainerCards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 500px) {
  .carouselContainerCards {
    grid-template-columns: repeat(1, 1fr);
  }
}
