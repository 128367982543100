.form-bg {
  background-image: url("../assets/images/formBg.png");
  background-size: 100% auto;
  background-position: top center;
}

.form-form input {
  opacity: 0.6;
  border: 3px solid rgba(255, 255, 255, 0.5) !important;
}

.editor-div {
  border: 3px solid rgba(255, 255, 255, 0.5) !important;
}

.editor-div .rdw-editor-toolbar {
  background-color: white;
  color: black !important;
}

.editor-div .rdw-editor-toolbar span {
  color: black !important;
}

.editor-div .rdw-editor-toolbar ul li {
  color: black;
}

.editor-div .DraftEditor-editorContainer {
  opacity: 0.6;
  overflow-y: scroll;
  max-height: 120px;
}

.editor-div .DraftEditor-editorContainer span {
  color: white;
}

body.active .editor-div .DraftEditor-editorContainer span {
  color: black;
}

.form-form textarea {
  opacity: 0.6;
  border: 3px solid rgba(255, 255, 255, 0.5);
}
p.text-opacity-5 {
  opacity: 0.5;
}
p.text-opacity-7 {
  opacity: 0.7;
}

body.active .form-bg {
  background-image: url("../assets/images/bgBottomLight.png");
  background-position: bottom center;
}

body.active .form-form-bg {
  background-image: url("../assets/images/formFormBgLight.png") !important;
  box-shadow: none;
}

body.active .form-form input,
.editor-div {
  color: rgb(0, 0, 0, 0.9);
  border-color: rgb(0, 0, 0, 0.3);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

body.active .form-form button {
  background-image: url("../assets/images/heroButtonBgLight.png") !important;
}
